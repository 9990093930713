.curationButton {
  margin: 20px 34px 0 0;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0;
  right: 0;
}

@media (max-width: 1850px) {
  .curationButton {
    position: fixed;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    margin: 10px 10px 0 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .curationButton {
    position: fixed;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    margin: 10px 10px 0 0;
    padding: 0;
  }
}
