.scrollBarWrapper {
  width: 100vw;
  margin-top: 85px;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  /* white-space: nowrap; */

  /* flex-direction: row-reverse; */

  display: flex;
  flex-direction: row;
}

.scrollBar {
  width: 3%;
  height: 75vh;
  background-color: rgba(128, 128, 128, 0.568);
  display: inline-block;
  margin-left: 24px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s forwards;
}

.move {
  overflow: hidden;
  z-index: -1;
  animation: moveLeft 0.28s forwards;
}

@keyframes moveLeft {
  to {
    overflow: hidden;

    transform: translateX(-260%);
  }
}

.scrollBar.hidden {
  overflow: hidden;
  opacity: 0;
}

.scrolling-bar {
  animation: scrollAnimation 0.5s linear;
}

.scrollBar-clear {
  width: 3%;
  height: 75vh;
  background-color: #fff;
  opacity: 0;
  display: inline-block;
  margin-left: 20px;
  text-align: center;
  overflow: hidden;
}
.scrollBar-reverse {
  position: absolute;
  margin-left: 30px;
  top: 0; /* adjust this as needed */
  width: 3%;
  height: 75vh;
  background-color: #fff;
  opacity: 0.2;
  display: inline-block;
  margin-left: 20px;
  text-align: center;
  overflow: hidden;
}
.scrollFooter {
  position: relative;
  margin-top: 50px;
  position: fixed;
  bottom: 0;
  transition: transform 1s ease-in-out;
  transform: translateY(1%);
  width: 19.4%;
  margin-left: 20px;
}

.scrollFooter.up {
  transform: translateY(-100%);
}

.scrollFooter p {
  color: #fff;
}

.scrollFooter-sub {
  position: absolute;
  bottom: -100%;
  left: 5px;
}

.scrollFooter-sub p {
  position: relative;
  color: #666666;
}
.scrollBarreverse {
  display: none;
}
.scrollBar-mobile {
  display: none;
}

@keyframes scrollLeft {
  0% {
    right: 0;
  }
  100% {
    right: -20px; /* 넘어가는 거리 조절 */
  }
}

.scrollLeft {
  animation: scrollLeft 0.5s linear;
}

/* 2번째 막대 */
.scrollBar.shrink {
  width: 3%;
  height: 75vh;
  display: inline-block;
  margin-left: 20px;
  overflow: hidden !important;
  animation: reduce3 0.3s forwards, moveLeftAndBack 0.5s; /* adjust duration as needed */
  transform-origin: top;
}

@keyframes moveLeftAndBack {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-150px); /* 원하는 거리만큼 이동 */
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes reduce3 {
  0% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.2);
  }
}
.scrollBar.none {
  overflow: hidden;
}

.scrollBar.shrink-1 {
  width: 3%;
  height: 75vh;
  display: inline-block;
  margin-left: 20px;
  text-align: center;
  position: absolute;
  animation: reduce11 0.5s forwards, moveLeftAndBack2 0.5s;
  transform-origin: top;
}
@keyframes reduce11 {
  0% {
    height: 20vh;
  }
  100% {
    height: 75vh;
  }
}
@keyframes reduce1 {
  0% {
    transform: scaleY(0.7);
  }
  100% {
    transform: scaleY(1);
  }
}
.scrollBar.none {
  visibility: hidden;
}

@keyframes moveLeftAndBack2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-150px); /* 원하는 거리만큼 이동 */
  }
  100% {
    transform: translateX(0);
  }
}
.scrollBar.shrink-2 {
  width: 3%;
  height: 75vh;
  display: inline-block;
  margin-left: 20px;
  text-align: center;
  position: absolute;
  animation: reduce2 0.4s forwards, moveLeftAndBack3 0.5s;
  transform-origin: top;
}
@keyframes reduce2 {
  0% {
    height: 75vh;
  }
  100% {
    height: 20vh;
  }
}
@keyframes moveLeftAndBack3 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-180px); /* 원하는 거리만큼 이동 */
  }
  100% {
    transform: translateX(0);
  }
}
.scrollBar.shrink-3 {
  width: 3%;
  height: 75vh;
  display: inline-block;
  margin-left: 20px;
  text-align: center;
  position: absolute;
  overflow: hidden;
  animation: reduce5 0.3s forwards, moveLeftAndBack4 0.5s forwards;
  transform-origin: top;
}
@keyframes reduce5 {
  0% {
    height: 25vh;
  }
  100% {
    height: 75vh;
  }
}
@keyframes moveLeftAndBack4 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-145px); /* 원하는 거리만큼 이동 */
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes moveLeftAndBack5 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100px); /* 원하는 거리만큼 이동 */
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes moveLeftAndBack6 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px); /* 원하는 거리만큼 이동 */
  }
  100% {
    transform: translateX(0);
  }
}
@media (max-width: 768px) {
  .scrollFooter {
    display: none;
  }

  .scrollBar {
    display: none;
  }
  .scrollBarreverse {
    position: absolute;
    width: 28%;
    height: 75vh;
    background-color: rgba(128, 128, 128, 0.568);
    display: inline-block;
    margin-left: 20px;
    text-align: center;
    overflow: hidden;
    transform-origin: top;
  }
  .scrollBar.shrink-1 {
    display: none !important;
  }
  .scrollBar.shrink-2 {
    display: none !important;
  }
  .scrollBar.shrink-3 {
    display: none !important;
  }
  .scrollBarmobile {
    width: 28%;
    height: 75vh;
    background-color: rgba(128, 128, 128, 0.568);
    display: inline-block;
    margin-left: 20px;
    text-align: center;
    overflow: hidden;
    transform-origin: top;
  }
  .scrollBarmobile1 {
    width: 28%;
    height: 75vh;
    position: relative;
    background-color: rgba(128, 128, 128, 0.568);
    display: inline-block;
    margin-left: 25px;
    text-align: center;
    right: -40%;
    transform-origin: top;
  }
  .scrollBarmobile.animate {
    height: 45vh;
    animation: reduce5 0.3s forwards, moveLeftAndBack5 0.5s forwards !important;
  }
  .scrollBarreverse.animate {
    height: 45vh;
    animation: reduce5 0.3s forwards, moveLeftAndBack5 0.5s forwards !important;
  }
  .scrollBarmobile1.animate {
    height: 45vh;
    animation: reduce5 0.3s forwards, moveLeftAndBack5 0.5s forwards !important;
  }
  .move {
    display: none;
  }
  .scrollBarWrapper {
    width: auto;
    position: fixed;

    z-index: -1;
  }
}
