.workImgWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.headerWorkWrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: flex-start;
  z-index: 9;
}

.workLogo {
  margin-right: auto;
  color: #fff;
  border-top: 3px solid #fff;
  margin-left: 30px;
  margin-top: 30px;
  z-index: 9;
}

.workNavBar {
  margin-left: 30px;
  margin-top: 30px;
  z-index: 9;
  position: absolute;
  top: 0;
  right: 0;
}

.workNavBar a {
  text-decoration: none;
  color: #000;
  margin: 30px;
}
.imageText {
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 40%;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
}

.imageText > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (max-width: 1850px) {
  .imageText > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageText h3 {
  /* font-size: 25px;
  font-weight: bold; */
  margin-bottom: 0;
}
.workHanwhaWrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.workHanwhaWrapper img {
  transition: transform 0.3s;
  width: 100%;
  max-height: 860px;
  object-fit: cover;
}

.workHanwhaWrapper:hover img {
  transform: scale(1.1);
  z-index: 1;
  opacity: 0.3;
}

.workHanwhaWrapper:hover .imageText {
  opacity: 1;
}

.workHubbingWrapper {
  width: 50%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.workHubbingWrapper:hover img {
  transform: scale(1.1);
  z-index: 1;
  opacity: 0.3;
}

.workHubbingWrapper:hover .imageText {
  opacity: 1;
}

.workHubbingWrapper img {
  object-fit: cover;
  transition: transform 0.3s;
  width: 100%;
}

.workTakeCareWrapper {
  width: 50%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.workTakeCareWrapper:hover img {
  transform: scale(1.1);
  z-index: 1;
  opacity: 0.3;
}

.workTakeCareWrapper:hover .imageText {
  opacity: 1;
}

.workTakeCareWrapper img {
  object-fit: cover;
  transition: transform 0.3s;
  width: 100%;
}

.workTakeCareWrapper > .imageText {
  width: 50%;
}

.workKpopWrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.workHubbingWrapper > .imageText {
  width: 50%;
}

.workKpopWrapper:hover img {
  transform: scale(1.1);
  z-index: 1;
  opacity: 0.3;
}

.workKpopWrapper:hover .imageText {
  opacity: 1;
}

.workKpopWrapper img {
  object-fit: cover;
  transition: transform 0.5s;
  width: 100%;
}

.lightUnderLine {
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: width 0.5s;
}

.workHanwhaWrapper:hover .lightUnderLine {
  width: 100%;
}

.workHubbingWrapper:hover .lightUnderLine {
  width: 100%;
}

.workTakeCareWrapper:hover .lightUnderLine {
  width: 100%;
}

.workKpopWrapper:hover .lightUnderLine {
  width: 100%;
}

.workUnderLine {
  border-bottom: 2px solid #a2a2a2;
  opacity: 0.3;
}

.mobileWorkWrapper {
  display: none;
}

@media (max-width: 1850px) {
}

@media (max-width: 768px) {
  .workNavBar {
    display: none;
  }
  .workLogo {
    margin-right: auto;
    color: #fff;
    border-top: 3px solid #fff;
    margin-left: 20px;
    margin-top: 20px;
    z-index: 1;
  }
  .workHanwha:hover img {
    transform: scale(1);
    z-index: 0;
    opacity: 1;
  }
  .imageText {
    display: none;
  }
  .workHanwhaWrapper:hover img {
    transform: scale(1);
    opacity: 1;
  }
  .workTakeCareWrapper:hover img {
    transform: scale(1);
    opacity: 1;
  }
}
