.mobileMenuContainer {
  position: fixed;
  top: 0;
  right: 0;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobileMenuButton {
  position: fixed;
  top: 0;
  right: 0;

  width: 25px;
  height: 25px;
  display: none;
  margin-top: 20px;
  margin-right: 20px;

  animation: fadein 0.5s;
  z-index: 7;
}

.menuContent {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 174px;
  height: 100vh;
  top: 0;
  right: 0;
  padding: 20px;
  padding-top: 70px;
  display: none;
  transform: translateX(100%);
  transition: all 0.5s ease-out;
  z-index: 6;
}

.mobileMenuButton.open + .menuContent {
  transform: translateX(0%);
}

.menuContent a {
  text-decoration: none;
}

.mobileMenuContainer .menuContent p {
  color: #fff;
  font: normal normal 500 16px/25px Poppins;
  letter-spacing: -0.32px;
  color: #ffffff;
  opacity: 1;
  text-align: right;

  margin-bottom: 28px;
}

@media (max-width: 768px) {
  .mobileMenuButton {
    display: block;
  }
  .menuContent {
    display: block;
  }

  /* .mobileMenuContainer .mobileMenuButton.open.reverse + .menuContent {
    animation: slide-out 0.5s ease forwards;
  }
  
  .mobileMenuContainer .mobileMenuButton + .menuContent {
    display: block;
    animation: slide-out 0.5s ease forwards;
  }
  
  .mobileMenuContainer .mobileMenuButton.reverse + .menuContent {
    animation: slide-in 0.5s ease forwards;
  }
  
  .mobileMenuContainer .menuContent.reverse {
    animation: slide-out 0.5s ease forwards;
  } */
}

/* @keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
} */

/* @keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
} */
