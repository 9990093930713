.plusButtonWrapper {
    position: absolute;

    left: 2%;
    width: 40px;
    height: 40px;
    z-index: 7;
    position: fixed;
}

@media (max-width: 768px) {
    .plusButtonWrapper {
        position: absolute;
        position: fixed;
        left: 4%;
        width: 30px;
        height: 30px;
    }
}