/* @import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Poppins:wght@300;400&display=swap"); */

.contactTextWrapper {
  color: #fff;
  position: relative;
  padding-top: 202px;
  margin-left: 34px;
}

.contactText2 {
  font-weight: bold;
  font-style: italic;
}

.request {
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 78px;
  margin-left: 34px;
}

.requestButton {
  width: 214px;
  height: 48px;
  padding: 0px 22px 0px 20px;
  margin-left: 91px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  background-color: #fff;
  cursor: none;
}

.requestButton:hover {
  background-color: #000;
  color: #fff;
  cursor: none;
}

.requestButton:hover span {
  background-color: #000;
  color: #fff;
  cursor: none;
}

.requestButton img {
  transition: filter;
  margin-left: 30px;
  transition: transform 0.3s;
}

.requestButton:hover img {
  transition: 0.3s;
  filter: invert(100%);
  transform: translateX(10px);
}

.requestButton:hover .rightArrow {
  transition: 0.3s;
}

.downloadWrapper {
  position: relative;

  margin-top: 249px;
  margin-left: 34px;
  margin-bottom: 256px;

  width: 292px;
  height: 38px;
  border-bottom: 1px solid #fff;
}

.downloadButton {
  background-color: transparent;
  border: none;
  color: #fff;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 0;
  transition: transform 0.3s;
  transform: translateY(-10%);
  cursor: none;
}

.download2Div {
  transition: transform 0.45s;
  transform: translateY(-10%);
}

.downloadButton:hover .download2Div {
  transform: translateY(0);
  cursor: none;
}

.strMobile {
  display: none;
}
@media (max-width: 768px) {
  .contactText1 {
  }
  .requestButton:hover {
    border: 1px solid #fff;
    background-color: #fff;
    cursor: none;
    color: #000;
  }
  .requestButton:hover img {
    transition: 0.3s;
    filter: invert(0);
    transform: translateX(0);
  }
  .download2Div {
  }

  .downloadButton {
    margin-right: 0;
  }
}

@media (max-width: 1850px) {
  .contactTextWrapper {
    padding-top: 177px;
    margin-left: 34px;
  }
  .request {
    margin-top: 78px;
    margin-left: 34px;
  }
  .downloadWrapper {
    margin-top: 249px;
    margin-left: 34px;
    margin-bottom: 256px;
  }
  .requestButton {
    width: 160px;
    height: 36px;
    padding: 0px 16px 0px 10px;
    margin-left: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #fff;
    background-color: #fff;
    cursor: none;
  }
}

@media (max-width: 786px) {
  .contactTextWrapper {
    padding-top: 150px;
    margin-left: 18px;
  }
  .request {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 32px;
    margin-left: 18px;
    margin-right: 50px;
  }
  .downloadWrapper {
    position: relative;
    width: 170px;
    height: 35px;
    margin-top: 193px;
    margin-left: auto;
    margin-right: 18px;
    margin-bottom: 256px;
  }

  .requestButton {
    padding: 0px 10px 0px 16px;
    margin-left: 0;
    margin-top: 52px;
    width: 156px;
    height: 40px;
  }
}
