/* @import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Poppins:wght@300;400&display=swap"); */

.curationWrapper {
  padding: 0;
  margin: 0;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.curationHeader {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  justify-content: flex-start;
  z-index: 6;
  
}

.curationLogo {
  margin-right: auto;
  color: #fff;
  border-top: 3px solid #fff;
  
  margin-left: 15px;
  margin-top: 30px;
  
  z-index: 6;
  
  position: absolute;
  top: 0;
}

.navBar {
  
  margin-left: 30px;
  margin-top: 30px;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  cursor: none;
}

.navBar a {
  text-decoration: none;
  color: #000;
  margin: 30px;
  cursor: none;
  
}
.navBarwhite a {
  text-decoration: none;
  color: white;
  margin: 30px;
  
}

a:hover {
  cursor: none;
}
.hubbingWrapper {
  left: 22.3%;
  width: 77vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  z-index: 2;
  background-color: #000;

}

.hubbingWrapper img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-color: #000;

  user-select: none;
}

.mobileFooter {
  display: none;
}

@keyframes curationSlide-in {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);  /* 애니메이션이 끝나면 모든 내용이 보이도록 설정 */
  }
}

@keyframes mobileSlide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.curationSlide-in {
  width: 100%;
  height: auto;
  animation: curationSlide-in 0.5s ease forwards;
  opacity: 1; /* 흐릿한 효과를 제거하고 완전히 불투명하게 표시 */
}

.blackScroll {
  position: absolute;
  bottom: 8%;
  left: 50%;
}

.whiteScroll {
  position: absolute;
  bottom: 8%;
  left: 50%;
}
.curationImgWrapper-m {
  display: none;
}
.mobileScrollBarWrapper {
  display: none;
}

/* 모바일 화면 */
@media (max-width: 768px) {
  .curationImgWrapper-m {
    width: 100%;
    height: 100%;
    display: block;
    float: right;
    margin-top: 87px;
  }
  .hubbingImg-m {
    width: 258px;
    height: 335px;
    float: right;
    object-fit: cover;
    overflow: hidden;

    animation: mobileSlide-in 0.5s ease forwards;
  }
  .hubbingWrapper {
    display: none;
  }
  .curationLogo {
    margin-top: 20px;
    
    margin-left: 20px;
  }
  .downloadButton {
    display: flex;
  }
  .mobileFooter {
    display: block;
  }
}
