*{
  cursor: none !important;
}

body {
  padding: 0;
  margin: 0;
  background-color: #000;
}

main {
  min-height: 100vh;
  height: fit-content;
}


.container {
  position: relative;
}

.cursor {
  position: fixed;
  background-color: black;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: 10000;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  pointer-events: none;
  transform: scale(1);
}

.video-background {
  position: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: auto;
  z-index: -1;
  overflow: hidden;
}

.video-element {
  position: fixed;
  width: 100vw;
  height: auto;
  z-index: -1;
  object-fit: cover;
  overflow: hidden;
}