.aboutTextWrapper {
  position: relative;
  width: 95%;
  margin: auto;

  width: 100vw;
  height: 100vh;

  z-index: 1;
}

.aboutText {
  position: absolute;

  top: 33.7%;

  right: 10%;

  transform: translateY(-50%);
}

.aboutText > p {
}

.text-animation {
  opacity: 0; /* 초기에 투명하게 설정합니다. */
  transform: translateY(100%); /* 아래로 이동한 상태로 시작합니다. */
  transition: opacity 1s, transform 1s; /* 투명도와 위치 변화에 대한 트랜지션을 설정합니다. */
}

.text-animation.active {
  opacity: 1; /* 투명도를 1로 설정하여 보이게 합니다. */
  transform: translateY(0%); /* 이동하지 않은 상태로 위치를 설정합니다. */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.aboutText2 {
  animation: fade-in 1s;
}

.underLine {
  margin-top: 38px;
  width: 0;
  border-bottom: 1px solid #a2a2a2;
  transition: width 1s;
}

@media (max-width: 786px) {
  .underLine {
    margin-top: 60px;
  }
}

.underLine.active {
  width: 100%;
}

.text2 p:last-child {
  margin-bottom: 40px;
}

.aboutScroll {
  position: fixed;
  bottom: 5%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.arrowBox {
  /* position: absolute; */
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 10;
}

.contactLightUnderLine {
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: width 0.5s;
}

.hide {
  display: none;
}

.processContainer {
  position: relative;
  width: 100%;
  margin: auto;

  z-index: 1;
  margin-top: 164px;
}

@media (max-width: 1850px) {
  .processContainer {
    margin-top: 174px;
  }
}

@media (max-width: 768px) {
  .processContainer {
    margin-top: 91px;
  }
}

.processContainer h2 {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
}

.processContainer p {
  /* margin-bottom: 5px; */
}

.categoryLine {
  border-top: 2px solid #585858;
}

.serviceWrapper h1 {
  margin-bottom: 20px;
}

.categoryWrapper2 {
  position: relative;
  border-bottom: 1px solid #868686;

  display: flex;
  align-items: end;
}

.category {
  margin-right: 25%;
}

.projectPWrapper p {
  margin: 0;
}

.projectPWrapper p:nth-child(2) {
  margin-bottom: 30px;
}

.projectPWrapper {
  border-bottom: 1px solid #ffffff;
}

.processContainer h1 {
  margin-bottom: 25px;
}

.categoryWrapper2 {
  margin-top: 80px;
  width: 85%;
  /* float: right; */
}

@media (max-width: 1850px) {
  .categoryWrapper2 {
    margin-top: 40px;
    width: 85%;
    /* float: right; */
  }
}

@media (max-width: 768px) {
  .categoryWrapper2 {
    margin-top: 40px;
    width: 85%;
    /* float: right; */
  }
}

.categoryWrapper2 .categoryNumber {
  position: absolute;
  top: -40px;
  left: 0;
  z-index: -1;
}

@media (max-width: 1850px) {
  .categoryWrapper2 .categoryNumber {
    position: absolute;
    top: -20px;
    left: 0;
  }
}

@media (max-width: 768px) {
  .categoryWrapper2 .categoryNumber {
    position: absolute;
    top: -30px;
    left: 0;
  }
}

.project {
  margin-left: 20%;
}

.projectList {
  width: 50%;
  /* float: right; */
  display: block;
}

.projectList p {
  text-align: left;
  /* margin-top: 5px; */
}

.partnerImgWrapperDiv {
  display: grid;
  grid-template-columns: repeat(7, minmax(100px, 1fr));
  grid-gap: 10px;
}

.partnerWrapper {
  position: relative;
  width: 95%;
  margin: auto;

  /* z-index: 1; */
  width: 95%;
  margin: auto;
  margin-bottom: 98px;
  margin-top: 150px;
}

@media (max-width: 1850px) {
  .partnerWrapper {
    margin-top: 205px;
  }
}

@media (max-width: 768px) {
  .partnerWrapper {
    margin-top: 100px;
  }

  .partnerWrapper h1 {
    padding-bottom: 24px;
  }
}

.partnerImgWrapper {
  display: flex;
  justify-content: space-around;
  display: inline-block;
}

.partnerImgWrapper img {
  width: 100%;
  height: auto;
}

.partnerWrapper h1 {
  border-bottom: 1px solid #ffffff;
}
.processContainer {
}

.processCategoryContainer {
  margin-top: 200px;
}

@media (max-width: 1850px) {
  .processCategoryContainer {
    margin-top: 90px;
  }
}

@media (max-width: 768px) {
  .processCategoryContainer {
    margin-top: 30px;
  }
}

.categoryContainer {
  position: relative;

  display: flex;
  flex-direction: column;

  height: auto;
}
@media (max-width: 768px) {
  .categoryContainer ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.categoryContainer hr {
  opacity: 0.5;
  width: 50%;
  position: absolute;
}

@media (max-width: 768px) {
  .navBar {
    display: none;
  }
  .arrowBox {
    left: 85%;
  }
  .aboutTextWrapper {
    position: relative;
    width: 95%;
    margin: auto;

    z-index: 1;
    width: 90%;
  }
  .aboutText {
    right: 0;
    top: 33.7%;
    transform: none;
  }
  .text-animation {
  }

  .serviceWrapper h1 {
    margin-bottom: 24px;
  }
  .processContainer h1 {
    margin: 0;
  }
  .projectPWrapper p {
  }
  .categoryWrapper2 .categoryNumber {
  }
  .categoryContainer ul {
    margin: 0;
    width: 100%;
  }
  .projectList p > span {
    display: inline-block;
    padding-left: 24%;
  }
  .categoryWrapper h2:nth-child(1) {
    display: block;
    border-bottom: 1px solid #585858;
    width: 100%;
  }
  .categoryWrapper h2:nth-child(2) {
  }

  .processContainer h1 {
    margin-bottom: 16px;
  }
  .categoryWrapper2 {
    width: 100%;
  }
  .categoryWrapper2 .project {
    margin-left: 24%;
  }
  .partnerImgWrapperDiv {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-gap: 10px;
  }
  .partnerWrapper h1 {
    margin: 0;
    text-align: left;
  }
  .partnerWrapper img {
    width: 30vw;
    height: 100%;
    text-align: center;
  }
  .partnerWrapper {
    width: 95%;
    height: 100%;
    text-align: center;
  }
  .aboutScroll {
    position: fixed;
    bottom: 5%;
    left: 50%;
  }
}
