/* @import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Poppins:wght@300;400&display=swap"); */

.MainBg {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: none;
  overflow-y: hidden;
  overflow-x: hidden;
  cursor: none;
}

.headerWrapper {
  background-color: #000;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.logo {
  margin-right: auto;
  color: #fff;

  
  
  
  z-index: 5;
  
  position: absolute;
  top: 0;
  cursor: none;
}



.BlurWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  pointer-events: none;
}

.BlurArea {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  backdrop-filter: blur(2px);
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 999999;
  transform: translate(-50%, -50%);
}

.BlurArea:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  z-index: 999999;
}

.content {
  position: absolute;
  z-index: 2;
  top: 372px;
  left: 112px;
  /* transform: translate(-50%, -50%); */
  color: white;
 
  opacity: 1;
  

  user-select: none;
}

.content p:first-child {
  
}

.content p:last-child {
  
}
.fade-out {
  animation: fade-out 1s forwards;
}

@keyframes fade-out {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  90% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    opacity: 1;
  }
  to {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    opacity: 0;
  }
}

.fill-box {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 1;
  pointer-events: none;
  transform: scaleX(0);
  transform-origin: top right;
  transition: transform 1s ease;

  z-index: 3;
}

.fill-box-animate {
  transform: scaleX(1);
}

@media (max-width: 1850px) {
  .content {
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 254px;
    left: 86px;
    color: white;
  }
}

/* 모바일 화면 */
@media (max-width: 768px) {
  .content {
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 287px;
    left: 28px;
    color: white;
  }
  .logo {
    margin-right: auto;
    color: #fff;
    
    
    z-index: 1;
    
  }
  .BlurArea {
    display: none;
  }

  .BlurArea:before {
    display: none;
  }
}
