.xButtonWrapper {
  position: absolute;
  position: fixed;
  top: 15%;
  left: 2%;
  width: 40px;
  height: 40px;
  z-index: 7;
}

@media (max-width: 768px) {
  .xButtonWrapper {
    position: absolute;
    position: fixed;
    left: 4%;
    top: 15%;
    width: 30px;
    height: 30px;
  }
}
