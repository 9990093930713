.headerWrapper {
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
  top: 0;
}

.logo {
  margin-right: auto;
  color: #fff;
  font-size: 20px;
  font: normal normal 600 20px/30px Poppins;
  font-weight: bold;
  z-index: 1;
}

@media (max-width: 1850px) {
  .logo {
    font: normal normal 600 14px/21px Poppins;
  }
}

.navBar {
  position: relative;
  font-size: 20px;

  z-index: 1;
}

.navBar a {
  /* text-decoration: none;
  color: #000;
  margin: 30px;
  font-weight: bold; */
}
