.detailImg {
  transition: width 0.5s ease-in-out;
  width: 100%;
  height: auto; /* 이미지 비율 유지 */
  float: right;
}

.detailImg.reduced {
  width: 70%; /* 원래 크기로 돌아올 때 너비를 100%로 설정 */
  float: right;
}

.detailPlusButton {
  width: 100%;
  height: 100%;
}

.customDiv {
  /* position: absolute; */
  position: fixed;
  /* position: sticky; */
  /* position: relative; */
  top: 30%;
  left: 34px;
  width: 30%;
  z-index: 1;
  color: #fff;
  font: normal normal 600 18px/36px Pretendard;
  white-space: nowrap;
  overflow: hidden;
}

.textDiv {
  width: 100%;
}

.textDiv h2 {
  margin-bottom: 206px;
  animation: slideIn 0.5s forwards;
  animation-delay: 1s;
  opacity: 0;
}

.textDiv .date {
  margin-bottom: 50px;
  animation: slideIn 0.5s forwards;
  animation-delay: 1.5s;
  opacity: 0;
}

.textDiv1Wrapper {
  display: flex;
  margin-bottom: 89px;
}

.textDiv2Wrapper {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dev {
  width: 50%;
  font: normal normal 400 18px Pretendard;
  animation: fadeIn 0.5s forwards;
  animation-delay: 2.5s;
  opacity: 0;
}

.job {
  width: 50%;
  font: normal normal 400 18px Pretendard;
  animation: slideIn 0.5s forwards;
  animation-delay: 2s;
  opacity: 0;
}

.detailBarWrapper {
  position: fixed;
  top: 15%;
  width: 30%;
  height: 100vh;

  display: flex;
  flex-direction: row;
  align-items: start;
}

.bar1Wrapper {
  width: 58px;
  height: 0;
  background-color: #fff;
  opacity: 0.2;
  display: inline-block;
  animation-name: slideDown1;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  overflow-x: hidden;
  animation-delay: 0.5s;
  margin-bottom: 60vh;
}

.bar2Wrapper {
  width: 58px;
  height: 0;
  background-color: #fff;
  opacity: 0.2;
  display: inline-block;
  margin-left: 20px;
  animation-name: slideDown2;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  overflow-x: hidden;
  animation-delay: 0.5s;
  margin-bottom: 20vh;
}

@media (max-width: 1850px) {
  .bar1Wrapper .bar2Wrapper {
    width: 36px;
  }
}

@media (max-width: 786px) {
  .bar1Wrapper .bar2Wrapper {
    width: 40px;
  }
}

@keyframes slideDown1 {
  0% {
    height: 0;
  }
  100% {
    height: 300px;
  }
}

@keyframes slideDown2 {
  0% {
    height: 0;
  }
  100% {
    height: 879px;
  }
}

@media (max-width: 1850px) {
  .bar1Wrapper {
    width: 36px;
  }
  .bar2Wrapper {
    width: 36px;
  }
  @keyframes slideDown1 {
    0% {
      height: 0;
    }
    100% {
      height: 186px;
    }
  }

  @keyframes slideDown2 {
    0% {
      height: 0;
    }
    100% {
      height: 522px;
    }
  }
}

@media (max-width: 768px) {
  .bar1Wrapper {
    width: 40px;
  }
  .bar2Wrapper {
    width: 40px;
  }
  @keyframes slideDown1 {
    0% {
      height: 0;
    }
    100% {
      height: 300px;
    }
  }

  @keyframes slideDown2 {
    0% {
      height: 0;
    }
    100% {
      height: 603px;
    }
  }
}

.mobileDetail {
  display: none;
}

@keyframes slideIn {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .detailImg {
    display: none;
  }
  .mobileDetail {
    display: block;
    width: 100%;
  }
  .customDiv {
    width: 100%;
    position: fixed;
    /* background-color: rgba(0, 0, 0, 0.5); */
    animation-name: slideInFromRight;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    top: 25%;
    margin-left: 18px;
  }
  @keyframes slideInFromRight {
    0% {
      left: 100%; /* 시작 위치 */
    }
    100% {
      left: 0; /* 최종 위치 */
    }
  }

  .textDiv .date {
    margin-bottom: 100px;
    animation-delay: 2s;
  }

  .textDiv .job {
    animation-delay: 2.5s;
  }

  .detailBarWrapper {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .detailBar1 {
    width: 10%;
    animation-delay: 1s;
  }

  .dev {
    animation: slideIn 0.5s forwards;
    animation-delay: 3s;
  }

  .detailBar2 {
    width: 10%;
    margin-left: 20px;
    animation-delay: 1s;
    margin-bottom: 120px;
  }
}

@media (max-width: 1850px) {
  .textDiv h2 {
    margin-bottom: 28px;
    animation: slideIn 0.5s forwards;
    animation-delay: 1s;
    opacity: 0;
  }

  .textDiv .date {
    margin-bottom: 114px;
    animation: slideIn 0.5s forwards;
    animation-delay: 1.5s;
    opacity: 0;
  }

  .textDiv1Wrapper {
    display: flex;
    margin-bottom: 78px;
  }
}

@media (max-width: 786px) {
  .textDiv {
    height: auto;
  }

  .textDiv h2 {
    margin-bottom: 20px;
    animation: slideIn 0.5s forwards;
    animation-delay: 1s;
    opacity: 0;
  }

  .textDiv .date {
    margin-bottom: 172px;
    animation: slideIn 0.5s forwards;
    animation-delay: 1.5s;
    opacity: 0;
  }

  .textDiv1Wrapper {
    display: flex;
    margin-bottom: 66px;
  }
}
